import { type ComponentType } from 'react';
import {
  useMutation,
  useQueryClient,
  type MutationFunction,
} from 'react-query';

import {
  http,
  invalidateGetRoomsQueryData,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';

import {
  type Project,
  type UpdateProjectXhrBody,
  updateProjectQueryData,
} from '../';

/**
 * PATCH - /api/v1/eames/projects/:id
 */

// HTTP

export interface UpdateProjectVariables {
  projectId: number;
  body: UpdateProjectXhrBody;
  muteError?: boolean;
  method?: 'put' | 'patch';
}

export type UpdateProjectXhrResponse = Project & {
  errors: Record<keyof UpdateProjectXhrBody, string[]>[];
};

/* add '400' to valid response statuses to handle errors directly in project edit component */
const validateStatus = (status: number) =>
  status === 400 || status === 422 || (status >= 200 && status < 300);

export const updateProjectXhr = ({
  projectId,
  body,
  muteError,
  method = 'patch',
}: UpdateProjectVariables): Promise<UpdateProjectXhrResponse> =>
  // Use 'put' only for edit project form
  http[method]<UpdateProjectXhrResponse>(
    `/api/v1/eames/projects/${projectId}`,
    body,
    {
      ...(muteError ? { validateStatus } : {}),
    }
  ).then((res) => res.data);

// MutationFn

type UpdateProjectMutation = MutationFunction<
  UpdateProjectXhrResponse,
  UpdateProjectVariables
>;

export const updateProjectMutationFn: UpdateProjectMutation = (variables) =>
  updateProjectXhr(variables);

// Hook
export const useUpdateProjectMutation = () => {
  const client = useQueryClient();
  return useMutation(updateProjectMutationFn, {
    onSuccess: (data, { projectId }) => {
      if (data.errors) return;
      updateProjectQueryData(client, projectId, data);
    },
  });
};

// With Mutation HOC

const withUpdateProjectMutationPropKey = 'updateProjectMutation';

export type WithUpdateProjectMutationProps = WithMutationPropsByMutation<
  typeof withUpdateProjectMutationPropKey,
  UpdateProjectMutation
>;

export function withUpdateProjectMutation<
  P extends WithUpdateProjectMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectMutationProps>> {
  return withMutation(
    withUpdateProjectMutationPropKey,
    updateProjectMutationFn,
    (client) => ({
      onSuccess: (data, { projectId, body: { moveItems } }) => {
        if (data.errors) return;

        updateProjectQueryData(client, projectId, data);

        if (moveItems) {
          invalidateGetRoomsQueryData(client, [projectId]);
        }
      },
    })
  )(Component as ComponentType<WithUpdateProjectMutationProps>);
}
