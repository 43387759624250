// TODO: rename this file to constants.js, and constants.js to theme.js
import { TRACKED_EVENTS } from 'js/utils/analytics';
import { type ActionOptions } from 'js/components/partial/ReactFilestack';
import {
  type ImageBase,
  type ImageType,
  ImageTypes,
} from './api/images/models';

// @TODO add config
export const ENVIRONMENT = 'development';
export const API_URL = '';
export const VESTA_URL = 'https://www.vestahome.com';

export const APP_NAME = 'eames';
export const APP_ID = 'eames.pm';
export const STORED_HEADERS = ['client', 'access-token', 'uid'];
export const PUBLIC_ROUTES = ['/eames/forgot'];
// @TODO - handle missing envs

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_DESIGNER = 'designer';
export const USER_ROLE_SALESPERSON = 'salesperson';
export const USER_ROLE_WAREHOUSE = 'warehouse';

export const PROJECT_STATUS_RESERVING = 'reserving';
export const PROJECT_STATUS_DELIVERY_REQUESTED = 'delivery_requested';
export const PROJECT_STATUS_PICKING = 'picking';
export const PROJECT_STATUS_ON_SITE = 'on_site';
export const PROJECT_STATUS_RETURN_REQUESTED = 'return_requested';
export const PROJECT_STATUS_RECEIVING = 'receiving';
export const PROJECT_STATUS_RETURNED = 'returned';
export const PROJECT_STATUS_CLOSED = 'closed';
export const PROJECT_STATUS_WALK_THROUGH_SCHEDULED = 'walk_through_scheduled';
export const PROJECT_STATUS_WALK_THROUGH_COMPLETED = 'walk_through_completed';
export const PROJECT_STATUS_LOST = 'lost';

export const RESERVATION_STATUSES = {
  [PROJECT_STATUS_ON_SITE]: 'on-site',
  [PROJECT_STATUS_RETURNED]: 'returned',
};

export type ProjectStatus = keyof typeof PROJECT_STATUSES;

export const PROJECT_STATUSES = {
  [PROJECT_STATUS_RESERVING]: 'Reserving',
  [PROJECT_STATUS_PICKING]: 'Picking',
  [PROJECT_STATUS_ON_SITE]: 'On-Site',
  [PROJECT_STATUS_RECEIVING]: 'Receiving',
  [PROJECT_STATUS_CLOSED]: 'Archived',
};

export type ProjectStatusObj =
  typeof PROJECT_STATUS_FLOW[keyof typeof PROJECT_STATUS_FLOW];

export const PROJECT_STATUS_FLOW = {
  [PROJECT_STATUS_RESERVING]: {
    label: 'Ready for delivery',
    endpoint: 'request_picking',
    track: TRACKED_EVENTS.PROJECT_STATUS_DELIVERY_REQUESTED,
  },
  [PROJECT_STATUS_DELIVERY_REQUESTED]: {
    label: 'Waiting for picking…',
    endpoint: false,
  },
  [PROJECT_STATUS_PICKING]: {
    label: 'On-Site',
    endpoint: 'stop_picking',
  },
  [PROJECT_STATUS_ON_SITE]: {
    label: 'Return requested',
    endpoint: 'end_project',
    track: TRACKED_EVENTS.PROJECT_STATUS_RETURN_REQUESTED,
  },
  [PROJECT_STATUS_RETURN_REQUESTED]: {
    label: 'Archived',
    endpoint: 'close_project',
    track: TRACKED_EVENTS.PROJECT_STATUS_CLOSE_REQUESTED,
  },
  [PROJECT_STATUS_RECEIVING]: {
    label: 'Archived',
    endpoint: 'close_project',
    track: TRACKED_EVENTS.PROJECT_STATUS_CLOSE_REQUESTED,
  },
  [PROJECT_STATUS_CLOSED]: {
    label: 'On site',
    endpoint: 'unarchive',
  },
  [PROJECT_STATUS_WALK_THROUGH_SCHEDULED]: {
    label: 'Reserving',
    endpoint: 'reserve',
  },
  [PROJECT_STATUS_WALK_THROUGH_COMPLETED]: {
    label: '',
    endpoint: false,
  },
  [PROJECT_STATUS_LOST]: {
    label: '',
    endpoint: false,
  },
};

export const PROJECT_TYPES = [
  'Full Staging',
  'Partial Staging',
  'Private Design',
  'Furniture Sale',
  'TurnKey',
  'MultiFamily',
  'E-Commerce',
  'Client Storage',
  'Luxury Lease',
  'Fernish B2B',
  'Other',
  'Internal Use',
] as const;

export const MAX_SHOWN_FILTER_ITEMS = 5;

export const PDF_CHECK_TIMEOUT = 5 * 1000;
// @TODO  Type is not use and don't mutch with incoming data on unassigned room (receive "Unassigned")
export const UNASSIGNED_ROOM_TYPE = 'unassigned';
export const UNASSIGNED_ROOM_NAME = 'Unassigned';

export const PRODUCT_MAX_MATERIALS = 4;
export const PRODUCT_MAX_FINISH_MATERIALS = 4;
export const PRODUCT_IMAGE_ORDER = [
  ImageTypes.Hero,
  ImageTypes.Detail,
  ImageTypes.InProject,
  ImageTypes.One,
  ImageTypes.Two,
  ImageTypes.Three,
  ImageTypes.Four,
  ImageTypes.Five,
];
export const PRODUCT_IMAGES_DEFAULT = PRODUCT_IMAGE_ORDER.reduce(
  (acc, imageType) => {
    acc[imageType] = { imageType, url: '' };
    return acc;
  },
  {} as Record<ImageType, ImageBase>
);

export const FILESTACK_KEY = 'A9wiNbSIQQkG1iCbdkCFLz';

export const FILESTACK_SETTINGS_FOR_PRODUCT_IMAGE: ActionOptions = {
  accept: 'image/*',
  maxFiles: 1,
  fromSources: ['local_file_system'],
  storeTo: {
    location: 's3',
    path: 'project/',
  },
};
