import React, {
  type HTMLAttributes,
  type ReactNode,
  type PropsWithChildren,
} from 'react';
import { Label, FormGroupStyled, Input } from './';

export interface Props
  extends Pick<
    HTMLAttributes<HTMLInputElement>,
    'onChange' | 'tabIndex' | 'id' | 'onFocus' | 'onBlur'
  > {
  value?: string | number;
  type?: string;
  invalid?: string[];
  required?: boolean;
  title?: ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}

export function FormGroup({
  children,
  value,
  id,
  invalid,
  onChange,
  title,
  tabIndex,
  required = false,
  ...args
}: PropsWithChildren<Props>) {
  if (!args.type) args.type = 'text';
  return (
    <FormGroupStyled error={id ? invalid?.includes(id) : undefined}>
      <Label htmlFor={id} required={required}>
        {title}
      </Label>
      {children || (
        <Input
          id={id}
          name={id}
          tabIndex={tabIndex}
          value={value}
          onChange={onChange}
          required={required}
          {...args}
        />
      )}
    </FormGroupStyled>
  );
}
