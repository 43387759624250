import React, {
  PropsWithChildren,
  type HTMLAttributes,
  type ReactNode,
} from 'react';
import styled, { type ThemeProps } from 'styled-components';

import IconCheck from 'js/icons/Check';
import Button from 'js/components/partial/Button';
import { type Theme } from 'js/theme';

type CheckboxProps = Pick<
  Props,
  'checked' | 'halfChecked' | 'disabled' | 'notCheckable'
>;

const Checkbox = styled(IconCheck)<CheckboxProps>`
  display: ${(props) => (props.checked ? 'block' : 'none')};
  left: 3px;
  position: absolute;
  top: 5px;
  width: 8px;
`;

const ButtonStyled = styled(Button)<CheckboxProps>`
  background: none;
  color: ${(props: ThemeProps<Theme> & CheckboxProps) =>
    props.theme.colors[
      props.disabled || props.notCheckable
        ? 'grayLight'
        : props.checked || props.halfChecked
        ? 'azure'
        : 'brownishGrey'
    ]};
  font-weight: 400;
  height: auto;
  margin-bottom: 4px;
  padding: 2px 0 3px 23px;
  position: relative;
  text-align: left;
  white-space: normal;
  opacity: ${(props) => (props.halfChecked ? '0.5' : '1')};

  &::before {
    background: ${(props: ThemeProps<Theme> & CheckboxProps) =>
      props.halfChecked
        ? props.theme.colors.soldWhite
        : props.theme.colors.white};
    border: 1px solid
      ${(props: ThemeProps<Theme> & CheckboxProps) =>
        props.theme.colors[
          props.checked && !props.disabled && !props.notCheckable
            ? 'azure'
            : 'whiteTwo'
        ]};
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04);
    content: '';
    left: 0;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 4px;
    transition: border-color 0.3s ease-in-out;
  }

  &:hover {
    color: ${(props: ThemeProps<Theme> & CheckboxProps) =>
      props.theme.colors[
        props.notCheckable || props.disabled
          ? 'grayLight'
          : props.checked
          ? 'azureHover'
          : 'brownishGreyHover'
      ]};
  }
`;

interface Props
  extends Pick<HTMLAttributes<HTMLButtonElement>, 'onClick' | 'className'> {
  checked?: boolean;
  halfChecked?: boolean;
  title?: ReactNode;
  disabled?: boolean;
  notCheckable?: boolean;
  dataId?: string;
  buttonTitle?: string;
}

function CheckboxButton({
  checked,
  halfChecked,
  children,
  className,
  disabled,
  notCheckable,
  onClick,
  dataId,
  buttonTitle,
}: PropsWithChildren<Props>) {
  return (
    <ButtonStyled
      className={className}
      onClick={onClick}
      checked={checked}
      halfChecked={halfChecked}
      disabled={disabled}
      notCheckable={notCheckable}
      link
      data-id={dataId}
      title={buttonTitle}
    >
      <Checkbox color="#293042" checked={checked} disabled={disabled} />
      {children}
    </ButtonStyled>
  );
}

export default CheckboxButton;
